// Material Dashboard Core JavaScript
import './material-dashboard/bootstrap-material-design.min';

// Material Dashboard Plugins
import 'perfect-scrollbar';
import 'moment';
import 'sweetalert2';
import 'jquery-validation';
import 'jquery-bootstrap-wizard';
import 'bootstrap-select';
import 'bootstrap-datetimepicker-npm';
import 'datatables.net';
import 'bootstrap-tagsinput';
import 'jasny-bootstrap';
import 'fullcalendar';
import 'jvectormap';
import 'nouislider';
import 'arrive';
import 'chartist';
import 'bootstrap-notify';
import './dropzone';
import './flatpickr';
// Material Dashboard Custom JavaScript
import './material-dashboard/material-dashboard';